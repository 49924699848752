/**
 * Holds the data  properties for a given line item table.
 */
export const businessPartnerDetailTablesProps = {
  business_partner_locations: {
    title: 'Location Addresses',
    ajaxURL:
      '/api/business_partners_location_addresses/?format=json&fields=id,location_address,location_kind,department,primary_location',
    columns: [
      { title: 'Location', field: 'location_address' },
      { title: 'Location Category', field: 'location_kind' },
      {
        title: 'Department',
        sort: false,
        formatter: function (cell) {
          if (cell.getRow().getData().department) {
            return cell.getRow().getData().department;
          } else {
            return `-`;
          }
        },
      },
      {
        title: 'Primary Location',
        sort: false,
        formatter: function (cell) {
          if (cell.getRow().getData().primary_location === true) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'View Details',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a href="/partners/business_partner_location_detail/${rowData.id}/" class="btn btn-sm btn-success">View</a>`;
        },
      },
    ],
  },
  business_partner_contact_persons: {
    title: 'Contact Persons',
    ajaxURL:
      '/api/business_partners_contact_persons/?format=json&fields=id,name_read,phone_read,email_read,default_contact_person',
    columns: [
      { title: 'Name', field: 'name_read' },
      { title: 'Primary Phone', field: 'phone_read' },
      { title: 'Primary Email', field: 'email_read' },
      {
        title: 'Primary Contact Person',
        sort: false,
        formatter: function (cell) {
          if (cell.getRow().getData().default_contact_person === true) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a href="/partners/edit_partner_contact_person/${rowData.id}/"><i class="fas fa-edit text-primary"></i></a>`;
        },
      },
      {
        title: 'Delete',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          if (cell.getRow().getData().default_contact_person !== true) {
            return `
            <a href="/navigation/logistics-customers-delete-contact-person/${rowData.id}/">
              <button
                class="btn btn-sm btn-danger rounded text-decoration-none font-weight-bold"
                 style="font-size:12px;">
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
              </button>
            </a>
            `;
          } else {
            return `
            <a>
              <button
                class="btn btn-sm  rounded text-decoration-none font-weight-bold"
                 style="font-size:14px;"
                 disabled
                 >
                    <i class="fa fa-trash-o text-muted" aria-hidden="true"></i>
              </button>
            </a>
            `;
          }
        },
      },
    ],
  },
  business_partner_contact_address: {
    title: 'Contact Addresses',
    ajaxURL:
      '/api/business_partners_contact_addresses/?format=json&fields=id,contact_value,contact_kind,primary_phone,primary_email',
    columns: [
      { title: 'Contact', field: 'contact_value' },
      { title: 'Contact Type', field: 'contact_kind' },
      {
        title: 'Primary Address',
        sort: false,
        formatter: function (cell) {
          if (
            cell.getRow().getData().primary_phone ||
            cell.getRow().getData().primary_email
          ) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a href="/partners/edit_business_partner_contact_address/${rowData.id}"><i class="fas fa-edit text-primary"></i></a>`;
        },
      },
      {
        title: 'Delete',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          if (!rowData.primary_phone && !rowData.primary_email) {
            return `
            <a href="/navigation/logistics-customers-delete-contact-address/${rowData.id}/">
              <button
                class="btn btn-sm btn-danger rounded text-decoration-none font-weight-bold"
                 style="font-size:12px;">
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
              </button>
            </a>
            `;
          } else {
            return `
            <a>
              <button
                class="btn btn-sm  rounded text-decoration-none font-weight-bold"
                 style="font-size:14px;"
                 disabled
                 >
                    <i class="fa fa-trash-o text-muted" aria-hidden="true"></i>
              </button>
            </a>
            `;
          }
        },
      },
    ],
  },
  business_partner_location_contact: {
    title: 'Location Contact Person',
    ajaxURL:
      '/api/business_partners_location_contact_person/?format=json&fields=id,contact_person,partner_location,primary_contact_person',
    columns: [
      {
        title: 'Contact Person',
        sort: false,
        formatter: function (cell) {
          return cell.getRow().getData().contact_person.name;
        },
      },
      {
        title: 'Phone',
        sort: false,
        formatter: function (cell) {
          return cell.getRow().getData().contact_person.phone;
        },
      },
      {
        title: 'Location Address',
        sort: false,
        formatter: function (cell) {
          return cell.getRow().getData().partner_location.location.address;
        },
      },
      {
        title: 'Location Type',
        sort: false,
        formatter: function (cell) {
          return cell.getRow().getData().partner_location.kind;
        },
      },
      {
        title: 'Primary Contact Person',
        sort: false,
        formatter: function (cell) {
          if (cell.getRow().getData().primary_contact_person) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a href="/partners/edit_partner_location_contact/${rowData.id}"><i class="fas fa-edit text-primary"></i></a>`;
        },
      },
      {
        title: 'Delete',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          if (!rowData.primary_contact_person) {
            return `
            <a href="/partners/delete_partner_location_contact/${rowData.id}/">
              <button
                class="btn btn-sm btn-danger rounded text-decoration-none font-weight-bold"
                 style="font-size:12px;">
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
              </button>
            </a>
            `;
          } else {
            return `
            <a>
              <button
                class="btn btn-sm  rounded text-decoration-none font-weight-bold"
                 style="font-size:14px;"
                 disabled
                 >
                    <i class="fa fa-trash-o text-muted" aria-hidden="true"></i>
              </button>
            </a>
            `;
          }
        },
      },
    ],
  },
  business_partner_payment_term: {
    title: 'Payment Term',
    ajaxURL:
      '/api/business_partners_payment_terms/?format=json&fields=id,payment_term_name,credit_days,payment_term_type,default_payment_term,closing_date',
    columns: [
      { title: 'Name', field: 'payment_term_name' },
      { title: 'Credit Days', field: 'credit_days_read' },
      {
        title: 'Closing Date',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().closing_date_read;
          return rowData ? rowData : `-`;
        },
      },
      { title: 'Payment Term', field: 'payment_term_type' },

      {
        title: 'Default Payment Term',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          if (rowData.default_payment_term === true) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return (
            '<a class="text-decoration-none" style="font-size:14px;"' +
            ` href="/partners/update_partner_credit_term/${rowData.id}"><i class="fas fa-edit text-primary" aria-hidden="true"></i></a>`
          );
        },
      },
    ],
  },
  business_partner_audit_logs: {
    title: 'Business Partner Audit Logs',
    ajaxURL:
      '/api/business_partners_transition_logs/?format=json&fields=id,created,status_from,status_to,user_name,note',
    columns: [
      {
        title: 'Created',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().created;
          let formattedDate = formatDate(rowData);
          return `<span class="d-block">${formattedDate}</span>`;
        },
      },
      { title: 'From', field: 'status_from' },
      { title: 'To', field: 'status_to' },
      {
        title: 'Created By',
        field: 'user_name',
      },
      { title: 'Notes', field: 'note' },
    ],
  },
  business_partner_documents: {
    title: 'Business Partner Documents',
    ajaxURL:
      '/api/business_partners_document_attachments/?format=json&fields=id,title,description,content_type',
    columns: [
      { title: 'Document Name', field: 'title' },
      {
        title: 'Description',
        field: 'description',
        formatter: function (cell) {
          return cell.getValue() ? cell.getValue() : '-';
        },
      },
      {
        title: 'Type',
        field: 'content_type',
        formatter: function (cell) {
          return cell.getValue() ? cell.getValue() : '-';
        },
      },
      {
        title: 'Download',
        formatter: function (cell) {
          let documentPK = cell.getRow().getData().id;
          return `<a href="/partners/download_business_partner_attachment/${documentPK}/"><i class="fa fa-download" aria-hidden="true"></i></a>`;
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          let documentPK = cell.getRow().getData().id;
          return `<a href="/partners/edit_business_partner_attachment/${documentPK}/"><i class="fas fa-edit"></i></a>`;
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          let documentPK = cell.getRow().getData().id;
          return `<a href="/partners/delete_business_partner_attachment/${documentPK}/"><i class="fa fa-trash" aria-hidden="true" style="color:red;"></i></a>`;
        },
      },
    ],
  },
};

export const formatDate = datetimeString => {
  // Create a Date object from the datetime string
  const dateTime = new Date(datetimeString);

  // Define options for formatting
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'Africa/Nairobi',
  };

  // Format the Date object
  return dateTime.toLocaleString('en-US', options);
};

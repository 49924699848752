export const branchMasterDetailTables = {
  branch_accounts: {
    title: 'Accounts',
    ajaxURL:
      '/api/branch-accounts/?format=json&fields=id,name,number,currency_balance,heading,heading_type,url,currency,account_current_balance,system_generated,identifier,',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Number', field: 'number' },
      { title: 'Balance', field: 'currency_balance' },
      {
        title: 'Type',
        field: 'heading',
        formatter: function (cell) {
          return cell.getRow().getData().heading_type;
        },
      },
      {
        title: 'Action',
        formatter: function (cell) {
          return (
            '<a class="btn btn-sm btn-success" style="font-size:12px;" href="' +
            cell.getRow().getData().url +
            '">View</a>'
          );
        },
      },
    ],
  },
  branch_contact_persons: {
    title: 'Contact Persons',
    ajaxURL:
      '/api/branch-contact-persons/?format=json&fields=id,contact_details,primary',
    columns: [
      {
        title: 'Name',
        field: 'contact',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.contact_details.name
            ? rowData.contact_details.name
            : '-';
        },
      },
      {
        title: 'Phone',
        field: 'contact',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.contact_details.phone
            ? rowData.contact_details.phone
            : '-';
        },
      },
      {
        title: 'Secondary Phone',
        field: 'contact',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.contact_details.secondary_phone
            ? rowData.contact_details.secondary_phone
            : '-';
        },
      },
      {
        title: 'Email',
        field: 'contact',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.contact_details.email
            ? rowData.contact_details.email
            : '-';
        },
      },
      {
        title: 'Secondary Email',
        field: 'contact',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.contact_details.secondary_email
            ? rowData.contact_details.secondary_email
            : '-';
        },
      },
      {
        title: 'Job Title',
        field: 'contact',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.contact_details.designation
            ? rowData.contact_details.designation
            : '-';
        },
      },
      {
        title: 'Primary Contact',
        sort: false,
        formatter: function (cell) {
          if (cell.getRow().getData().primary === true) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Action',
        sort: false,
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<div>
          <a href="/navigation/setup/edit-branch-contact-person/${rowData.id}/"><i class="fas fa-edit  me-3" aria-hidden="true"></i></a>
          <a href="/navigation/setup/delete-branch-contact-person/${rowData.id}/"><i class="fa fa-trash text-danger" aria-hidden="true"></i></a></div>`;
        },
      },
    ],
  },
  branch_contact_addresses: {
    title: 'Contact Persons',
    ajaxURL:
      '/api/branch-contact-address/?format=json&fields=id,contact_details,primary',
    columns: [
      {
        title: 'Contact',
        field: 'contact',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.contact_details.value;
        },
      },
      {
        title: 'Type',
        field: 'contact',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.contact_details.contact_type;
        },
      },
      {
        title: 'Kind',
        field: 'contact',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.contact_details.kind;
        },
      },
      {
        title: 'Primary Contact',
        sort: false,
        formatter: function (cell) {
          if (cell.getRow().getData().primary === true) {
            return `<i class='fa fa-check-circle text-success' style='font-size:1.5rem;'></i>`;
          } else {
            return `<i class='fa fa-times-circle text-danger' style='font-size:1.5rem;'></i>`;
          }
        },
      },
      {
        title: 'Action',
        sort: false,
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<div>
          <a href="/navigation/setup/edit-branch-contact-address/${rowData.id}/"><i class="fas fa-edit  me-3" aria-hidden="true"></i></a>
          <a href="/navigation/setup/delete-branch-contact-address/${rowData.id}/"><i class="fa fa-trash text-danger" aria-hidden="true"></i></a></div>`;
        },
      },
    ],
  },
  branch_users: {
    title: 'Users',
    ajaxURL: '/api/users/?format=json&fields=id,name,phone,email',
    columns: [
      { title: 'Name', field: 'name' },
      { title: 'Number', field: 'phone' },
      { title: 'Email', field: 'email' },
      {
        title: 'View User Details',
        formatter: function (cell) {
          return `<a class="btn btn-sm btn-success" style="font-size:12px;" href="/users/user/detail/${
            cell.getRow().getData().id
          }">View</a>`;
        },
      },
      {
        title: 'Unassign User From Branch',
        formatter: function (cell) {
          const branch_id = document.querySelector('#branch_id').innerText;
          const user_id = cell.getRow().getData().id;
          const url = `/navigation/setup/branch/unassign-user/${user_id}/branch/${branch_id}/`;

          return `<a href="${url}">
            <i class="fa fa-trash text-danger" aria-hidden="true"></i>
            </a>`;
        },
      },
    ],
  },

  branch_weekly_schedules: {
    title: 'Branch Weekly Schedule',
    ajaxURL:
      '/api/slots/?format=json&fields=id,title,start,end,has_cancelled_occurrences',
    columns: [
      {
        title: 'Day Of The Week',
        field: 'title',
      },
      {
        title: 'Opening Hours',
        field: 'start',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          if (rowData.has_cancelled_occurrences) {
            return 'closed';
          } else {
            return rowData.start;
          }
        },
      },
      {
        title: 'Closing Hours',
        field: 'end',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          if (rowData.has_cancelled_occurrences) {
            return '-';
          } else {
            return rowData.end;
          }
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a href="/schedules/update_branch_schedule/${rowData.id}/"><i class="fas fa-edit"></i></a>`;
        },
      },
    ],
  },
  date_specific_schedules: {
    title: 'Branch Weekly Schedule',
    ajaxURL:
      '/api/occurrences/?format=json&fields=id,start_date,start,end,title,cancelled',
    columns: [
      {
        title: 'Date',
        field: 'start',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.start_date;
        },
      },
      {
        title: 'Day Of The Week',
        field: 'title',
      },
      {
        title: 'Opening Hours',
        field: 'start',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          if (rowData.cancelled) {
            return 'closed';
          } else {
            return rowData.start;
          }
        },
      },
      {
        title: 'Closing Hours',
        field: 'end',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          if (rowData.cancelled) {
            return '-';
          } else {
            return rowData.end;
          }
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a href="/navigation/setup/update-branch-occurrence/${rowData.id}/"><i class="fas fa-edit"></i></a>`;
        },
      },
    ],
  },
  branch_territories: {
    title: 'Partners',
    ajaxURL: '/api/branch_territories/?format=json&fields=id,partner,territory',
    columns: [
      {
        title: 'Name',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.territory_name;
        },
      },
      {
        title: 'Action',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return `<a
            class="btn btn-sm btn-success"
            style="font-size:12px;"
            href="${rowData.territory_url}">View Territory</a>`;
        },
      },
    ],
  },
};

export const deliveryMasterDetailTables = {
  delivery_history: {
    title: 'Delivery History',
    ajaxURL:
      '/api/delivery-item-logs/?format=json&fields=note,user_name,formated_statuses,hf_created',
    columns: [
      {
        title: 'Created',
        field: 'hf_created',
      },
      {
        title: 'Status From',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().formated_statuses;
          return rowData.status_from;
        },
      },
      {
        title: 'Status To',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().formated_statuses;
          return rowData.status_to;
        },
      },
      {
        title: 'User',
        field: 'user_name',
      },
      {
        title: 'Note',
        field: 'note',
      },
    ],
  },
  deliveriesPriceLogs: {
    title: 'Delivery Item Price Log',
    ajaxURL:
      '/api/sales-order-line-price-logs/?format=json&fields=updated,updated_by,rate_card,rating_table,quantity,tax_name,tax_amount,total_price,user_name',
    columns: [
      {
        title: 'Created By',
        field: 'updated_by',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let userName = rowData.user_name ? rowData.user_name : null;
          return `${userName}`;
        },
      },
      {
        title: 'Date',
        field: 'updated',
      },
      {
        title: 'Rate Card',
        field: 'rate_card',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.rate_card !== null ? rowData.rate_card.name : '-';
        },
      },
      {
        title: 'Rating Table',
        field: 'rating_table',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.rating_table !== null
            ? rowData.rating_table.name
            : '-';
        },
      },
      { title: 'Quantity', field: 'quantity' },

      {
        title: 'Tax',
        field: 'tax_name',
      },
      {
        title: 'Tax Amount',
        field: 'tax_amount',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let taxAmount = rowData.tax_amount;
          return `${taxAmount} KES`;
        },
      },

      {
        title: 'Total Amount',
        field: 'total_price',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let TotalPrice = rowData.total_price;
          return `${TotalPrice} KES`;
        },
      },
    ],
  },
};

export const collectionMasterDetailTables = {
  collection_lines: {
    title: 'Collection Line Items',
    ajaxURL:
      '/api/collection-line-item/?format=json&fields=id,class_of_service_name,quantity,metadata,subtotal,tax_amount,unit_price,total_price,currency,discount,collection_status',
    columns: [
      { title: 'Class Of Service', field: 'class_of_service_name' },
      { title: 'Quantity', field: 'quantity' },
      {
        title: 'Weight (Kg)',
        field: 'metadata',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.metadata.weight;
        },
      },
      {
        title: 'Subtotal',
        field: 'subtotal',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let subtotal = (rowData.total_price - rowData.tax_amount).toFixed(2);
          return `${subtotal} ${rowData.currency}`;
        },
      },
      {
        title: 'Tax Amount',
        field: 'tax_amount',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let taxAmount = rowData.tax_amount;
          return `${taxAmount} ${rowData.currency}`;
        },
      },
      {
        title: 'Unit Price',
        field: 'unit_price',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let unitPrice = rowData.unit_price;
          return `${unitPrice} ${rowData.currency}`;
        },
      },
      {
        title: 'Total Amount',
        field: 'total_price',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let TotalPrice = rowData.total_price;
          return `${TotalPrice} ${rowData.currency}`;
        },
      },
      {
        title: 'Discount',
        field: 'discount',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let discount = rowData.discount;
          return `${discount} ${rowData.currency}`;
        },
      },
      {
        title: 'Price Logs',
        formatter: function (cell) {
          return `<a href="/navigation/logistics/collections/collection-line_price-log/${
            cell.getRow().getData().id
          }/">Price Log</a>`;
        },
      },
      {
        title: 'Edit',
        formatter: function (cell) {
          let collectionStatus = cell.getRow().getData().collection_status;
          let isDraft =
            collectionStatus === 'DRAFT' || collectionStatus === 'READY';
          let href = isDraft
            ? `/navigation/logistics/collections/edit/collection-line/${cell.getRow().getData().id}/`
            : '#';
          return isDraft
            ? `<a href="${href}"><i class="fas fa-edit"></i></a>`
            : `<a onclick="return false;" href="${href}"><i class="fas fa-edit text-muted"></i></a>`;
        },
      },
      {
        title: 'Delete',
        sort: false,
        formatter: function (cell) {
          let collectionStatus = cell.getRow().getData().collection_status;
          let isDraft = collectionStatus === 'DRAFT';
          let href = isDraft
            ? `/navigation/logistics/collections/delete/collection-line/${
                cell.getRow().getData().id
              }/`
            : '#';
          return isDraft
            ? `<a href="${href}"><i class="fa fa-trash text-danger"></i></a>`
            : `<a onclick="return false;" href="${href}"><i class="fa fa-trash text-muted"></i></a>`;
        },
      },
    ],
  },
  collection_lines_price_log: {
    title: 'Collection Line Item Price Log',
    ajaxURL:
      '/api/collection-line-item-price-logs/?format=json&fields=id,updated,updated_by,rate_card,rating_table,quantity,tax_name,tax_amount,total_price,currency,user_name',
    columns: [
      {
        title: 'Created By',
        field: 'updated_by',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let userName = rowData.user_name ? rowData.user_name : null;
          return `${userName}`;
        },
      },
      {
        title: 'Date',
        field: 'updated',
      },
      {
        title: 'Rate Card',
        field: 'rate_card',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.rate_card !== null ? rowData.rate_card.name : '-';
        },
      },
      {
        title: 'Rating Table',
        field: 'rating_table',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          return rowData.rating_table !== null
            ? rowData.rating_table.name
            : '-';
        },
      },
      { title: 'Quantity', field: 'quantity' },

      {
        title: 'Tax',
        field: 'tax_name',
      },
      {
        title: 'Tax Amount',
        field: 'tax_amount',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let taxAmount = rowData.tax_amount;
          return `${taxAmount} ${rowData.currency}`;
        },
      },

      {
        title: 'Total Amount',
        field: 'total_price',
        formatter: function (cell) {
          let rowData = cell.getRow().getData();
          let TotalPrice = rowData.total_price;
          return `${TotalPrice} ${rowData.currency}`;
        },
      },
    ],
  },
  collection_transition_history: {
    title: 'Collection History',
    ajaxURL:
      '/api/collection-logs/?format=json&fields=note,user_name,formated_statuses,hf_created',
    columns: [
      {
        title: 'Created',
        field: 'hf_created',
      },
      {
        title: 'Status From',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().formated_statuses;
          return rowData.status_from;
        },
      },
      {
        title: 'Status To',
        formatter: function (cell) {
          let rowData = cell.getRow().getData().formated_statuses;
          return rowData.status_to;
        },
      },
      {
        title: 'User',
        field: 'user_name',
      },
      {
        title: 'Note',
        field: 'note',
      },
    ],
  },
};
